import { css } from '@emotion/react'
import { AxiosResponse } from 'axios'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { mq, rem } from '../../styles/mixin.styles'
import { colors, fontSize, space } from '../../styles/variables.styles'
import { generatePath } from '../../utils/customPath'
import { mailData, sendEmail } from '../../utils/sendMail'
import { Modal } from '../common/Modal'

type FormValues = {
  firstName: string
  lastName: string
  lastKana: string
  firstKana: string
  email: string
  contactBody: string
  departmentName: string
  companyName: string
  tel: string
  checkPrivacy: boolean
}

/**
 * ContactForm
 *
 * @returns form
 */
export const ContactForm = () => {
  const [openModal, setOpenModal] = useState(false)
  const [isSendError, setIsSendError] = useState(false)
  const [fetchErrorMessage, setFetchErrorMessage] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm<FormValues>({ mode: 'onChange' })

  const onSubmit = handleSubmit(async (data) => {
    const sendData = {
      name: `${data.lastName} ${data.firstName}(${data.lastKana} ${data.firstKana})`,
      email: data.email,
      body: data.contactBody,
      department: data.departmentName,
      company: data.companyName,
      tel: data.tel
    }
    const res: AxiosResponse<mailData> | undefined = await sendEmail(sendData)
    if (typeof res === 'string') {
      // fetch error
      setFetchErrorMessage(res)
      setIsSendError(true)
      setOpenModal(true)
    } else {
      // complete
      reset()
      setOpenModal(true)
    }
  })

  return (
    <>
      <form onSubmit={onSubmit} css={formStyles}>
        <p className="text-attention">
          <em aria-label="必須項目">*</em>は必須項目です
        </p>
        <div className="form__item">
          <label htmlFor="companyName" className="form__label">
            <span>会社名</span>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input {...register('companyName')} id="companyName" type="text" placeholder="例）五十鈴株式会社" />
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="departmentName" className="form__label">
            <span>部署名</span>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input {...register('departmentName')} id="departmentName" type="text" placeholder="例）開発本部第一課" />
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="lastName" className="form__label">
            <span>お名前</span>
            <em aria-label="必須項目">*</em>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input
                {...register('lastName', { required: true })}
                id="lastName"
                type="text"
                placeholder="姓"
                className={errors.lastName && 'is-error'}
              />
              <input
                {...register('firstName', { required: true })}
                id="firstName"
                type="text"
                placeholder="名"
                className={errors.firstName && 'is-error'}
              />
            </div>
            <div className="form__error-area">
              {(errors.lastName?.type === 'required' || errors.firstName?.type === 'required') && (
                <p>名前を入力してください</p>
              )}
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="lastKana" className="form__label">
            <span>ふりがな</span>
            <em aria-label="必須項目">*</em>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input
                {...register('lastKana', {
                  required: true,
                  pattern: {
                    // eslint-disable-next-line no-irregular-whitespace
                    value: /^[ぁ-んー　]*$/,
                    message: 'ひらがなで入力してください'
                  }
                })}
                id="lastKana"
                type="text"
                placeholder="せい"
                className={errors.lastKana && 'is-error'}
              />
              <input
                {...register('firstKana', {
                  required: true,
                  pattern: {
                    // eslint-disable-next-line no-irregular-whitespace
                    value: /^[ぁ-んー　]*$/,
                    message: 'ひらがなで入力してください'
                  }
                })}
                id="firstKana"
                type="text"
                placeholder="めい"
                className={errors.firstKana && 'is-error'}
              />
            </div>
            <div className="form__error-area">
              {(errors.lastKana?.type === 'required' || errors.firstKana?.type === 'required') && (
                <p>ふりがなを入力してください</p>
              )}
              {(errors.lastKana?.type === 'pattern' || errors.firstKana?.type === 'pattern') && (
                <p>ひらがなで入力してください</p>
              )}
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="tel" className="form__label">
            <span>電話番号</span>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input {...register('tel')} id="tel" type="tel" placeholder="例）03-1234-5678" />
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="email" className="form__label">
            <span>メールアドレス</span>
            <em aria-label="必須項目">*</em>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <input
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'メールアドレスの形式が不正です'
                  }
                })}
                id="email"
                type="email"
                placeholder="例）example@isuzu.com"
                className={errors.email && 'is-error'}
              />
            </div>
            <div className="form__error-area">
              {errors.email?.type === 'required' && <p>メールアドレスを入力してください</p>}
              {errors.email?.type === 'pattern' && <p>メールアドレスの形式が不正です</p>}
            </div>
          </div>
        </div>
        <div className="form__item">
          <label htmlFor="contactBody" className="form__label">
            <span>お問い合わせ内容</span>
            <em aria-label="必須項目">*</em>
          </label>
          <div className="form__content">
            <div className="form__input-area">
              <textarea
                {...register('contactBody', { required: true })}
                id="contactBody"
                maxLength={10000}
                placeholder="ご相談やお問い合わせの内容を入力してください"
                className={errors.contactBody && 'is-error'}
              />
            </div>
            <div className="form__error-area">
              {errors.contactBody?.type === 'required' && <p>お問い合わせ内容を入力してください</p>}
            </div>
          </div>
        </div>
        <div className="form__item checkbox-only">
          <div className="form__content">
            <div className="form__input-area">
              <input
                {...register('checkPrivacy', { required: true })}
                id="checkPrivacy"
                type="checkbox"
                className={errors.checkPrivacy && 'is-error'}
              />
              <label htmlFor="checkPrivacy">
                <span>
                  <Link to={generatePath('privacy')}>プライバシーポリシー</Link>に同意する
                </span>
                <em aria-label="必須項目">*</em>
              </label>
            </div>
            <div className="form__error-area">
              {errors.checkPrivacy?.type === 'required' && <p>プライバシーポリシーへの同意が必要です</p>}
            </div>
          </div>
        </div>
        <div className="form__item submit-only">
          <button type="submit" disabled={!isValid}>
            送信
          </button>
        </div>
      </form>
      {isSendError ? (
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="送信エラーが発生しました"
          link="/"
          linkName="TOPへ戻る"
          subtitle="ご不便をおかけして申し訳ありません。"
        >
          <p>
            エラーメッセージ:
            <br />
            {fetchErrorMessage}
          </p>
        </Modal>
      ) : (
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="送信が完了しました"
          link="/"
          linkName="TOPへ戻る"
          subtitle="お問い合わせありがとうございました。"
        >
          <p>
            内容を確認後、
            <br />
            近日中にお返事させていただきます。
          </p>
        </Modal>
      )}
    </>
  )
}

const formStyles = css`
  width: min(calc(100% - ${space.md} * 2), ${rem(570)});
  margin: 0 auto;
  .text-attention {
    text-align: right;
    font-size: ${fontSize.sm};
    padding: ${rem(10)} 0;
  }
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    border: 1px solid ${colors.grayBorder.hex};
    border-radius: ${rem(2)};
    padding: ${rem(10)};
    width: 100%;
    &::placeholder {
      color: ${colors.textGray2.hex};
    }
    &.is-error {
      border-color: ${colors.error.hex};
    }
  }
  input[type='checkbox'] {
    border: 1px solid ${colors.grayBorder.hex};
    border-radius: ${rem(2)};
    width: ${rem(20)};
    height: ${rem(20)};
  }
  textarea {
    min-height: 10em;
  }
  button[type='submit'] {
    display: flex;
    background-color: ${colors.mainBlue.hex};
    color: ${colors.white.hex};
    font-size: ${rem(18)};
    padding: ${rem(13)} ${rem(45)};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: ${rem(4)};
    letter-spacing: 0.4em;
    &:after {
      content: ' ';
      margin-left: -0.4em;
    }
    &:hover {
      background-color: rgba(${colors.mainBlue.rgb}, 0.8);
    }
    &:disabled {
      box-shadow: none;
      background-color: ${colors.grayBorder.hex};
    }
  }
  .form__label {
    font-weight: 700;
    padding: ${rem(10)} 0;
    display: flex;
    + .form__input-area {
      margin-top: ${rem(10)};
    }
  }
  .form__content {
    width: 100%;
  }
  .form__input-area {
    display: flex;
    gap: ${rem(10)};
    width: 100%;
  }
  em,
  .form__error-area {
    color: ${colors.error.hex};
  }
  em {
    font-style: normal;
  }
  .form__item + .form__item {
    margin-top: ${rem(20)};
  }
  .form__item {
    &.checkbox-only {
      justify-content: center;
      align-items: center;
      .form__content {
        width: auto;
        .form__input-area {
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.submit-only {
      display: flex;
      justify-content: center;
      margin-top: ${rem(30)};
    }
  }
  ${mq('lg')} {
    .form__item {
      display: flex;
      justify-content: space-between;
      .form__label {
        min-width: 9em;
      }
      .form__content {
        width: ${rem(410)};
      }
    }
  }
`
