import React from 'react'
import { ContactForm } from '../components/contents/ContactForm'

import Single from '../components/contents/Single'

type Props = {
  location: {
    [key: string]: string
  }
}

const ContactPage = ({ location }: Props) => {
  return (
    <Single
      slug="contact"
      readText="iPaSを活用した調達業務改善、調達DXに関してお気軽にお問い合わせください。"
      pathname={location.pathname}
    >
      <ContactForm />
    </Single>
  )
}

export default ContactPage
