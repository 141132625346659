import axios, { AxiosRequestConfig } from 'axios'

export type mailData = {
  name: string
  email: string
  body: string
  tel?: string
  company?: string
  department?: string
}

/**
 * sendEmail
 *
 * @param mailData send data from mail form
 * @returns Promise from axios
 */
export const sendEmail = async ({ name, email, body, company, department, tel }: mailData) => {
  const options: AxiosRequestConfig = {
    url: 'https://4mnl7tkqn0.execute-api.ap-northeast-1.amazonaws.com/v1/send',
    method: 'POST',
    data: {
      name: name,
      email: email,
      body: body,
      company: company,
      department: department,
      tel: tel
    },
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }
  try {
    const { data } = await axios(options)
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message)
      return error.message
    } else {
      console.log('unexpected error: ', error)
      return 'An unexpected error occurred'
    }
  }
}
